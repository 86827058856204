import "./components/scrollToTop";
import "./components/customInfo";
import { select } from "./utils/function";

// import {
//   getItemLocalStorage,
//   markFavoritePlayers,
//   objectLength,
// } from "./utils/function";
// import {
//   leaderBoardAddFavoritePlayer,
//   leaderboardContent,
//   leaderboardCreateRow,
// } from "./components/leaderboard";

// import { arrayEquals } from "./utils/function";

// window.addEventListener(
//   "storage",
//   (e) => {
//     const body = document.documentElement;

//     const dataFavoritePlayers = body
//       ?.getAttribute("data-favorite-players")
//       ?.split(",")
//       .map((item) => Number(item));

//     const getItemFromLocalStorage = getItemLocalStorage("HQfavoritePlayers");

//     body.setAttribute("data-favorite-players", getItemFromLocalStorage);

//     // console.log("dataFavoritePlayers", dataFavoritePlayers);
//     // console.log("getItemFromLocalStorage", getItemFromLocalStorage);

//     if (!arrayEquals(dataFavoritePlayers || [], getItemFromLocalStorage)) {
//       body.setAttribute("data-favorite-players", getItemFromLocalStorage);
//       // console.log("different");

//       markFavoritePlayers("update");

//       // update leaderboard
//       leaderboardContent?.insertAdjacentElement(
//         "beforeend",
//         leaderboardCreateRow("update")
//       );
//       leaderBoardAddFavoritePlayer();
//     }
//   },
//   false
// );

// https://www.designcise.com/web/tutorial/how-to-detect-if-the-browser-tab-is-active-or-not-using-javascript
// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#browser_compatibility
// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#example

// window.addEventListener("focus", function (event) {
//   console.log("has focus");
// });

// window.addEventListener("blur", function (event) {
//   console.log("lost focus");
// });

document.addEventListener("DOMContentLoaded", function () {
  const rootElement = document.documentElement;
  window.addEventListener("scroll", () => {
    const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
    const header = select("header");
    if (rootElement.scrollTop / scrollTotal > 0.1) {
      header.setAttribute("style", "top: -50px");
    }
    if (rootElement.scrollTop / scrollTotal > 0.2) {
      header.classList.add("fixed-top");
      header.removeAttribute("style");
    } else {
      header.classList.remove("fixed-top");
      header.removeAttribute("style");
    }
  });
});
