const scrollToTopBtn = document.querySelector(".scroll-to-top");
const rootElement = document.documentElement;

function handleScroll() {
  const scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
  if (rootElement.scrollTop / scrollTotal > 0.2) {
    scrollToTopBtn.classList.add("show-scroll-to-top");
  } else {
    scrollToTopBtn.classList.remove("show-scroll-to-top");
  }
}

function scrollToTop() {
  // Scroll to top logic
  rootElement.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

scrollToTopBtn?.addEventListener("click", scrollToTop);
document.addEventListener("scroll", handleScroll);
